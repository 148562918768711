import React from 'react'

const Stash = (props) => {
  return (
    <article>
      <h1>Article H1 title</h1>
      <h2>H2 Title</h2>
      <h3>H3 Title</h3>
      <h3>H3 Title</h3>
      <h4>H4 Title</h4>
      <h2>H2 Title</h2>
      <h3>H3 Title</h3>
      <h3>H3 Title</h3>
      <h4>H4 Title</h4>
      <h4>H4 Title</h4>
      <h5>H5 Title</h5>
      <h5>H5 Title</h5>
      <h6>H6 Title</h6>
      <h6>H6 Title</h6>
    </article>
  )
}

export default Stash
